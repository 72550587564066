import './PastWordsDisplay.css';

function PastWordsDisplay(props) {
  let wordList = props.wordList;
  return <div>
    {wordList.map(word => {
      return <div className={`pastWord ${word.got?'got':'missed'}`}>{word.word}</div>;
    })}
  </div>
}

export default PastWordsDisplay;
