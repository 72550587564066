import './instructions.css';
import './../gamePage/gridRow.css';

function Instructions() {
  return (
    <div className="Instructions">
      <div className="flex mb-3 instructions">
        <div><b>Instructions</b></div>
        <div>1. <b>Solve Wordles!</b> Race to finish your set of Wordles before your opponent.</div>
        <div>2. <b>Manage your lives!</b> If you fail to solve the Wordle, you will lose a life.</div>
        <div>3. <b>Sabotage your opponent!</b> For each Wordle you solve, one of your opponent's letters will randomly break and your opponent will no longer be able to see if a letter guess in this position is correct.</div>
        <div><b>Game Guide</b></div>
        <div className="row mt-2">
          <div className={`col-md-1`}>
            <div className={`gridrow-color-unknown gridrow-letter-sm`}></div>
          </div>
          <div className='col-md-6'>Unknown: Letter is unknown.</div>
        </div>
        <div className="row mt-2">
          <div className={`col-md-1`}>
            <div className={`gridrow-color-nomatch gridrow-letter-sm`}></div>
          </div>
          <div className='col-md-6'>No Match: Letter is wrong.</div>
        </div>
        <div className="row mt-2">
          <div className={`col-md-1`}>
            <div className={`gridrow-color-partmatch gridrow-letter-sm`}></div>
          </div>
          <div className='col-md-6'>Partial Match: Letter is right; position is wrong.</div>
        </div>
        <div className="row mt-2">
          <div className={`col-md-1`}>
            <div className={`gridrow-color-match gridrow-letter-sm`}></div>
          </div>
          <div className='col-md-6'>Match: Letter is right; position is right.</div>
        </div>
        <div className="row mt-2">
          <div className={`col-md-1`}>
            <div className={`gridrow-color-broken gridrow-letter-sm`}></div>
          </div>
          <div className='col-md-6'>Broken: Letter was sabotaged by your opponent</div>
        </div>
        <div><b>Stats</b></div>
        <div>{`❤️: Remaining Lives`}</div>
        <div>{`⭐: Number of Wordles Completed`}</div>
      </div>
    </div>
  );
}


export default Instructions;
