import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';

import gameClient from '../../clients/GameClient.js';
import './index.css';

function VictoryPage() {
  let navigate = useNavigate();

  return (
    <div className="VictoryPage text-center fullScreen">
      <div className="position-absolute top-50 start-50 translate-middle">
        <h1 className='bright'>You won!</h1>
        <br />
        <br />
        <img src='/win.gif' alt="Winner" width="400"></img>
        <br />
        <br />
        <br />
        <MDBBtn onClick={() => {
            navigate('/');
          }}>Play Again!</MDBBtn>
      </div>
    </div>
  );
}

export default VictoryPage;
