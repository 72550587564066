import './statDisplay.css';

function StatDisplay(props) {
  let playerName = props.playerName
  let lives = props.lives;
  let progress = props.progress;
  let isTiny = props.tiny;
  let fontClass = isTiny ? "stat-sm p-1 m-1" : "stat-lg p-1 m-1";
  return (
    <div className="GridRow">
      <div className="d-flex flex-row mb-1 row">
        <div className={`${fontClass} col align-self-start`}>{`${playerName}`}</div>
        <div className={`${fontClass} col align-self-end`}>{`⭐ x ${progress}`}</div>
      </div>
    </div>
  );
}

export default StatDisplay;
