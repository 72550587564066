import {useState, useEffect, useRef} from 'react';
import { MDBInput } from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";

import gameClient from '../../clients/GameClient.js';

import './index.css';
import GridRow from './GridRow.js';
import Keyboard from './Keyboard.js';
import PastWordsDisplay from './PastWordsDisplay.js';
import StatDisplay from './StatDisplay.js';

import CannotConnectWidget from '../../widgets/cannotConnect';

function GamePage() {
  let navigate = useNavigate();
  const [lettersPerWord, setLettersPerWord] = useState(5);
  const [wordRows, setWordRows] = useState(6);
  const [guessWord, setGuessWord] = useState('');
  const [myLetterBoard, setMyLetterBoard] = useState([]);
  const [myTextureBoard, setMyTextureBoard] = useState([]);
  const [otherTextureBoard, setOtherTextureBoard] = useState([]);
  const [name, setName] = useState([]);
  const [opponentName, setOpponentName] = useState([]);
  const [gameStats, setGameStats] = useState({myLives:3,opponentLives:3,myProgress:0,opponentProgress:0});
  const [pastWords, setPastWords] = useState([]);

  const [inputIndex, setInputIndex] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    let pastWordsClosure = [];
    gameClient.attachListener('gameEvent', (evt) => {
      if (evt.tag==='boards') {
        setMyLetterBoard(evt.letterBoard);
        setMyTextureBoard(evt.textureBoard);
      }
      if (evt.tag==='otherTextureBoard') {
        setOtherTextureBoard(evt.textureBoard);
      }
      if (evt.tag==='stats') {
        setGameStats({
          myLives: evt.myLives,
          myProgress: evt.myProgress,
          opponentLives: evt.opponentLives,
          opponentProgress: evt.opponentProgress
        });
      }
      if (evt.tag=='gotWord') {
        let word = evt.word;
        pastWordsClosure = [...pastWordsClosure, {word, got: true}];
        setPastWords(pastWordsClosure);
      }
      if (evt.tag=='missedWord') {
        let word = evt.word;
        pastWordsClosure = [...pastWordsClosure, {word, got: false}];
        setPastWords(pastWordsClosure);
      }
      if (evt.tag==='youWin') {
        gameClient.cleanEvents();
        navigate('/victory');
      }
      if (evt.tag==='youLose') {
        gameClient.cleanEvents();
        navigate('/loss')
      }
      if (evt.tag==='settings') {
        setLettersPerWord(evt.numLetters);
        setWordRows(evt.attempts);
      }
    }, true);
    gameClient.attachListener('setName', (evt) => {
      if (evt.tag==='playerName') {
        setName(evt.playerName);
      }
      if (evt.tag==='opponentName') {
        setOpponentName(evt.playerName);
      }
    }, true);
  }, []);

  useEffect(() => {
    inputRef.current && inputRef.current.setSelectionRange(inputIndex, inputIndex);
  });

  let letterHash = {};
  for (let i=0;i<myLetterBoard.length;i++) {
    let letter = myLetterBoard[i];
    let texture = myTextureBoard[i];
    if (texture=='GREEN' || texture=='YELLOW' && letterHash[letter]!='GREEN' || texture=='RED' && letterHash[letter]!='GREEN' && letterHash[letter]!='YELLOW') {
      letterHash[letter] = texture;
    }
  }

  let opponentGrid = [];
  for (let i=0;i<wordRows;i++) {
    let letters = [' ',' ',' ',' ',' ',' '];
    let colors = otherTextureBoard.slice(lettersPerWord*i, lettersPerWord*i+lettersPerWord);
    opponentGrid.push(<GridRow letters={letters} colors={colors} length={lettersPerWord} tiny/>);
  }
  let myGrid = [];
  for (let i=0;i<wordRows;i++) {
    let letters = [' ',' ',' ',' ',' ',' '];
    if (myLetterBoard.length>=lettersPerWord*i+lettersPerWord) {
      letters = myLetterBoard.slice(lettersPerWord*i, lettersPerWord*i+lettersPerWord);
    }
    let colors = myTextureBoard.slice(lettersPerWord*i, lettersPerWord*i+lettersPerWord);
    myGrid.push(<GridRow letters={letters} colors={colors} length={lettersPerWord} />);
  }

  let submitGuess = () => {
    if (guessWord.length===lettersPerWord) {
      console.log('Submitting word ' + guessWord);
      setGuessWord('');
      gameClient.gameEvent({tag:'guessWord', guess:guessWord});
    }
  }

  return (
    <div className="GamePage fullScreen">
      <CannotConnectWidget />
      <div className="position-absolute top-0 start-0 translate-top">
        <PastWordsDisplay wordList={pastWords} />
      </div>
      <div className="position-absolute top-0 end-0 translate-top">
        {opponentGrid}
        <StatDisplay playerName={opponentName} lives={gameStats.opponentLives} progress={gameStats.opponentProgress} tiny />
      </div>
      <div className="position-absolute top-50 start-50 translate-middle">
        <StatDisplay playerName={name} lives={gameStats.myLives} progress={gameStats.myProgress} />
        {myGrid}
        <MDBInput inputRef={inputRef} label='Your Guess' type='text' size='lg' id="wordInput" value={guessWord} onChange={evt => {
          setInputIndex(evt.target.selectionStart);
          let inputStr = evt.target.value.toUpperCase().substring(0,lettersPerWord);
          inputStr = inputStr.replace(/[^A-Za-z]/g, '');
          setGuessWord(inputStr);
        }} onKeyDown={evt => {
          if (evt.key === 'Enter') {
            submitGuess();
          }
        }} />
        <Keyboard letterHash={letterHash} clickLetter={(l) => {
          if (l=='ENTER') {
            submitGuess();
            return;
          }
          if (l=='BKSP') {
            setGuessWord(guessWord.substring(0, guessWord.length-1));
            return;
          }
          let inputStr = guessWord + l;
          inputStr = inputStr.toUpperCase().substring(0,lettersPerWord);
          setGuessWord(inputStr);
        }}/>
      </div>
    </div>
  );
}

export default GamePage;
