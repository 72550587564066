import {useState, useEffect} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';

import gameClient from '../../clients/GameClient.js';
import './index.css';

import CannotConnectWidget from '../../widgets/cannotConnect';

function GameRoom() {
  let navigate = useNavigate();
  const [roomCode, setRoomCode] = useState('');
  const [lettersPerWord, setLettersPerWord] = useState(6);
  const [guessesAllowed, setGuessesAllowed] = useState(7);
  const [wordsToWin, setWordsToWin] = useState(5);
  const [leadToWin, setLeadToWin] = useState(3);
  const [canStart, setCanStart] = useState(false);
  const [playerNames, setPlayerNames] = useState([]);
  const [canEdit, setCanEdit] = useState(true);
  let params = useParams();

  useEffect(() => {
    gameClient.attachListener('activityEvent', (evt) => {
      if (evt.tag=='createdRoom') {
        setRoomCode(evt.code);
      }
      if (evt.tag=='updateOptions') {
        setLettersPerWord(evt.lettersPerWord);
        setGuessesAllowed(evt.attempts);
        setWordsToWin(evt.wordsToWin);
        setLeadToWin(evt.leadToWin);
      }
      if (evt.tag=='updatePlayers') {
        setPlayerNames(evt.playerNames);
        setCanEdit(evt.leader);
        if (evt.playerNames.length==2) {
          setCanStart(true);
        }
      }
    }, true);
    gameClient.attachListener('gameEvent', evt => {
      if (evt.tag=='starting') {
        navigate('/waiting');
      }
    })
    if (!params.roomCode) {
      gameClient.createRoom();
    }
  }, []);

  let lettersPerWordOptions = [5,6,7,8,9,10];
  let lettersPerWordController = <MDBBtnGroup>
    {lettersPerWordOptions.map(n => {
      return <MDBBtn color={lettersPerWord==n?'primary':'info'} disabled={!canEdit} onClick={() => {
        gameClient.activityEvent({tag:'updateOptions',lettersPerWord:n});
      }}>{n}</MDBBtn>
    })}
  </MDBBtnGroup>

  let guessesAllowedOptions = [5,6,7,8,9,10];
  let guessesAllowedController = <MDBBtnGroup>
    {guessesAllowedOptions.map(n => {
      return <MDBBtn color={guessesAllowed==n?'primary':'info'} disabled={!canEdit} onClick={() => {
        gameClient.activityEvent({tag:'updateOptions',attempts:n});
      }}>{n}</MDBBtn>
    })}
  </MDBBtnGroup>

  let wordsToWinOptions = [1,2,3,4,5,6,7,8,9,10];
  let wordsToWinController = <MDBBtnGroup>
    {wordsToWinOptions.map(n => {
      return <MDBBtn color={wordsToWin==n?'primary':'info'} disabled={!canEdit} onClick={() => {
        gameClient.activityEvent({tag:'updateOptions',wordsToWin:n});
      }}>{n}</MDBBtn>
    })}
  </MDBBtnGroup>

  let leadToWinOptions = [1,2,3,4,5];
  let leadToWinController = <MDBBtnGroup>
    {leadToWinOptions.map(n => {
      return <MDBBtn color={leadToWin==n?'primary':'info'} disabled={!canEdit} onClick={() => {
        gameClient.activityEvent({tag:'updateOptions',leadToWin:n});
      }}>{n}</MDBBtn>
    })}
  </MDBBtnGroup>

  return (
    <div className="GameRoom text-center fullScreen">
      <CannotConnectWidget />
      <div className="position-absolute top-50 start-50 translate-middle">
        {roomCode && [
          <h1 className='bright'>{`Code: ${roomCode}`}</h1>,
          <br />,
          <br />
        ]}
        <div className='bright'>Letters Per Word</div>
        {lettersPerWordController}
        <br />
        <br />
        <div className='bright'>Guesses Allowed</div>
        {guessesAllowedController}
        <br />
        <br />
        <div className='bright'>Number of Points To Win</div>
        {wordsToWinController}
        <br />
        <br />
        <div className='bright'>Number of Points Ahead To Win</div>
        {leadToWinController}
        <br />
        <br />
        <br />
        {playerNames.length==2 && [
          <p className='bright'>{`${playerNames[0]} vs ${playerNames[1]}`}</p>,
        ]}
        <MDBBtn disabled={!canStart || !canEdit} onClick={() => {
          gameClient.activityEvent({tag:'start'});
        }}>Begin Game!</MDBBtn>
      </div>
    </div>
  );
}

export default GameRoom;
