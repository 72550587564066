import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

import WelcomePage from './pages/welcomePage';
import WaitingPage from './pages/waitingPage';
import GamePage from './pages/gamePage';
import GameRoom from './pages/gameRoom';
import VictoryPage from './pages/victoryPage';
import LossPage from './pages/lossPage';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<WelcomePage />}></Route>
          <Route exact path="/waiting" element={<WaitingPage />}></Route>
          <Route exact path="/game" element={<GamePage />}></Route>
          <Route path="/gameRoom" element={<GameRoom />}>
            <Route exact path=":roomCode" element={<GameRoom />} />
          </Route>
          <Route exact path="/victory" element={<VictoryPage />}></Route>
          <Route exact path="/loss" element={<LossPage />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
