import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { MDBBtn, MDBBtnGroup } from 'mdb-react-ui-kit';

import gameClient from '../../clients/GameClient.js';
import './index.css';

function LossPage() {
  let navigate = useNavigate();

  return (
    <div className="LossPage text-center fullScreen">
      <div className="position-absolute top-50 start-50 translate-middle">
        <h1 className='bright'>You lost!</h1>
        <br />
        <br />
        <video loop
          muted
          autoPlay
          playsInline width="300"
          src="/loss.mp4" type="video/mp4" />
        <br />
        <br />
        <br />
        <MDBBtn onClick={() => {
            navigate('/');
          }}>Play Again!</MDBBtn>
      </div>
    </div>
  );
}

export default LossPage;
