import { io } from 'socket.io-client'

import env from '../env.js';

class GameClient {
	constructor() {
		this.connected = false;
		this.listeners = {
		  joinGameResponse: () => {},
		  activityEvent: () => {},
		  gameEvent: () => {},
		  setName: () => {},
		  gameStart: () => {},
		  joinedRoom: () => {}
		}
		this.pastEvents = {
		  joinGameResponse: [],
		  activityEvent: [],
		  gameEvent: [],
		  gameStart: [],
		  setName: [],
		  joinedRoom: []
		};
	}

	attachListener(type, func, getPastEvents=false) {
	  if (this.listeners[type]) {
	    this.listeners[type] = func;
	    if (getPastEvents) {
	      this.pastEvents[type].forEach(pastEvent => func(pastEvent));
	    }
	  } else {
	    console.log(`Unable to attach listener of type ${type} to game client`);
	  }
	}

	removeListener(type) {
	  this.listeners[type] = () => {};
	}

	cleanEvents(eventType) {
	  if (!eventType) {
	    this.pastEvents = {
        joinGameResponse: [],
        activityEvent: [],
        gameEvent: [],
        gameStart: [],
        setName: [],
        joinedRoom: []
      };
	  } else {
	    this.pastEvents[eventType] = [];
	  }
	}

	async setName(evt) {
		this.tryConnectThen(() => {
		  console.log('Sending setName request');
      this.socket.emit('setName', evt);
		});
	}

	async joinQueue() {
		this.tryConnectThen(() => {
		  console.log('Sending joinQueue request');
      this.socket.emit('joinQueue');
		});
	}

	async createRoom() {
		this.tryConnectThen(() => {
		  console.log('Sending createRoom request');
      this.socket.emit('createRoom');
		});
	}

	async joinRoom(roomCode) {
		this.tryConnectThen(() => {
		  console.log('Sending joinRoom request');
      this.socket.emit('joinRoom', roomCode);
		});
	}

	async gameEvent(evt) {
		this.tryConnectThen(() => {
		  console.log('Sending game event');
      this.socket.emit('gameEvent', evt);
		});
	}

	async activityEvent(evt) {
    this.tryConnectThen(() => {
      console.log('Sending activity event');
      this.socket.emit('activityEvent', evt);
    });
  }

	async tryConnectThen(cb) {
	  if (this.connected) {
	    return cb();
	  }
	  let socketOptions = {reconnectionDelay: 30000, reconnectionDelayMax: 300000};
	  if (env.ENV != 'local') {
	    socketOptions = {...socketOptions, path: "/game/socket.io/", transports: ['websocket']};
	  }
    this.socket = io(env.GAME_URL, socketOptions);
		this.socket.on('connect', () => {
			console.log('connected to server with socket id', this.socket.id)
			this.connected = true;
			cb();
			cb = () => {};
			if (this.connectedChanged) {
        this.connectedChanged(this.connected);
      }
		});
		this.socket.on('disconnect', () => {
			console.log('disconnected from server')
			this.connected = false;
			cb = () => {};
			if (this.connectedChanged) {
        this.connectedChanged(this.connected);
      }
		});
		this.socket.on('connect_error', () => {
      console.log('server error')
      this.connected = false;
      cb = () => {};
      if (this.connectedChanged) {
        this.connectedChanged(this.connected);
      }
    });
    this.socket.on('activityEvent', evt => {
      console.log('Received activity event: ');
      console.log(evt);
      this.listeners.activityEvent(evt);
      this.pastEvents.activityEvent.push(evt);
    });
    this.socket.on('gameEvent', evt => {
      console.log('Received game event: ');
      console.log(evt);
      this.listeners.gameEvent(evt);
      this.pastEvents.gameEvent.push(evt);
    });
    this.socket.on('setName', evt => {
      console.log('Received set name: ');
      console.log(evt);
      this.listeners.setName(evt);
      this.pastEvents.setName.push(evt);
    });
    this.socket.on('joinedRoom', evt => {
      console.log('Received joined room: ');
      console.log(evt);
      this.listeners.joinedRoom(evt);
      this.pastEvents.joinedRoom.push(evt);
    });
	}
}

const client = new GameClient()
export default client
