import { MDBBtn } from 'mdb-react-ui-kit';

import './Keyboard.css';

function KeyboardRow(props) {
  let letters = props.letters;
  let clickLetter = props.clickLetter;
  let letterHash = props.letterHash;
  return <div>
    {letters.map(l => {
      let keyColorClass = ({
        'RED': 'keyboard-color-nomatch',
        'YELLOW': 'keyboard-color-partmatch',
        'GREEN': 'keyboard-color-match'
      }[letterHash[l]]) || 'keyboard-color-unknown';
      return <MDBBtn className={`keyboardLetter ${keyColorClass}`} onClick={() => {
        clickLetter(l);
      }}>{l}</MDBBtn>
    })}
  </div>
}

function Keyboard(props) {
  let clickLetter = props.clickLetter;
  let letterHash = props.letterHash;
  return <div className='keyboard'>
    <KeyboardRow clickLetter={clickLetter} letterHash={letterHash} letters={['Q','W','E','R','T','Y','U','I','O','P']} />
    <KeyboardRow clickLetter={clickLetter} letterHash={letterHash} letters={['A','S','D','F','G','H','J','K','L']} />
    <KeyboardRow clickLetter={clickLetter} letterHash={letterHash} letters={['ENTER','Z','X','C','V','B','N','M','BKSP']} />
  </div>
}

export default Keyboard;
