import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import { useNavigate } from "react-router-dom";
import {useState, useEffect, useRef} from 'react';
import gameClient from '../../clients/GameClient.js';
import storageClient from '../../clients/LocalStorageClient.js';
import generatePlayerName from '../../util/RandomNameGenerator.js';

import './index.css';
import Banner from './Banner.js';
import Instructions from './Instructions.js';
import CannotConnectWidget from '../../widgets/cannotConnect';

function WelcomePage() {
  const [playerName, setPlayerName] = useState('');
  const [joiningFriendRoom, setJoiningFriendRoom] = useState(false);
  const [joiningRoomCode, setJoiningRoomCode] = useState('');
  const roomCodeInputRef = useRef(null);
  let navigate = useNavigate();

  useEffect(() => {
    gameClient.attachListener('joinedRoom', (evt) => {
      navigate('/gameRoom/' + evt.roomCode);
    });
  }, []);

  useEffect(() => {
    if (storageClient.getPlayerName()) {
      setPlayerName(storageClient.getPlayerName());
    } else {
      setPlayerName(generatePlayerName());
    }
  }, []);

  useEffect(() => {
    roomCodeInputRef.current && roomCodeInputRef.current.focus();
  }, [joiningFriendRoom]);

  return (
    <div className="WelcomePage fullScreen">
      <CannotConnectWidget />
      <div className="position-absolute top-0 translate-top">
        <Banner />
      </div>
      
      <div className="position-absolute top-50 start-50 translate-middle">
      <MDBInput label='Name' type='text' size='lg' id="name" value={playerName} onChange={evt => {
          console.log('Submitting name ' + playerName);
          setPlayerName(evt.target.value);
          storageClient.savePlayerName(evt.target.value);
        }}>
        </MDBInput>
        <MDBBtn rounded size="lg" active className="p-4 m-3"
          onClick={() => {
            gameClient.joinQueue();
            gameClient.setName(playerName);
            gameClient.cleanEvents('gameEvent');
            navigate('/waiting/');
          }}
        >
          Random Opponent
        </MDBBtn>
        <MDBBtn rounded size="lg" active className="p-4 m-3"
          onClick={() => {
            gameClient.setName(playerName);
            gameClient.cleanEvents('gameEvent');
            navigate('/gameRoom/');
          }}
        >
          Create Private Room
        </MDBBtn>
        <MDBBtn rounded size="lg" active className="p-4 m-3" onClick={() => {
          gameClient.cleanEvents('gameEvent');
          if (!joiningFriendRoom) {
            setJoiningFriendRoom(true);
            return;
          }
          if (joiningRoomCode.length>=4) {
            console.log('Joining room ' + joiningRoomCode);
            gameClient.setName(playerName);
            gameClient.joinRoom(joiningRoomCode);
          }
        }}>
          Join Friend Room
          {joiningFriendRoom && [
            <br />,
            <MDBInput id="roomCodeInput" placeholder="Room Code" inputRef={roomCodeInputRef} value={joiningRoomCode} onChange={evt => {
              setJoiningRoomCode(evt.target.value.toUpperCase());
              let inputStr = evt.target.value.toUpperCase();
              inputStr = inputStr.replace(/[^A-Za-z]/g, '');
              setJoiningRoomCode(inputStr);
            }} onKeyDown={evt => {
              if (evt.key === 'Enter') {
                console.log('Joining room ' + joiningRoomCode);
                gameClient.setName(playerName);
                gameClient.joinRoom(joiningRoomCode);
              }
            }} />
          ]}
        </MDBBtn>
        <Instructions />
        
      </div>
    </div>
  );
}

export default WelcomePage;
