import './gridRow.css';

function GridRow(props) {
  let letters = props.letters;
  let colors = props.colors;
  let length = props.length;
  let isTiny = props.tiny;
  let letterClass = isTiny ? "gridrow-letter-sm p-1 m-1" : "gridrow-letter p-1 m-1";

  let letterDivs = [];
  for (let i=0;i<length;i++) {
    let letterState = colors[i];
    let letter = letters[i];
    if (!letterState) {
      letterState = 'BLUE';
    }
    if (!letter) {
      letter = '';
    }
    let letterColorClass = {
      'BLUE': 'gridrow-color-unknown',
      'RED': 'gridrow-color-nomatch',
      'YELLOW': 'gridrow-color-partmatch',
      'GREEN': 'gridrow-color-match',
      'BROKEN': 'gridrow-color-broken',
    }[letterState];
    letterDivs.push(<div className={`${letterClass} ${letterColorClass}`}>{letter}</div>);
  }
  return (
    <div className="GridRow">
      <div className="d-flex flex-row mb-1">
        {letterDivs}
      </div>
    </div>
  );
}

export default GridRow;
