import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import gameClient from '../../clients/GameClient.js';
import './index.css';

import CannotConnectWidget from '../../widgets/cannotConnect';

function WaitingPage() {
  let navigate = useNavigate();
  const [statusText, setStatusText] = useState();

  let statusComponent;
  if (!statusText) {
    statusComponent = <h2>Waiting For Opponent...</h2>;
  } else {
    statusComponent = <h1 className="bright">{statusText}</h1>;
  }

  useEffect(() => {
    gameClient.attachListener('gameEvent', (evt) => {
      if (evt.tag=='starting') {
        if (evt.delay==3) {
          setStatusText('READY...');
        }
        if (evt.delay==2) {
          setStatusText('SET...');
        }
        if (evt.delay==1) {
          setStatusText('GO...');
        }
      }
      if (evt.tag=='started') {
        navigate('/game/');
      }
    }, true);
  }, []);

  return (
    <div className="WaitingPage text-center fullScreen">
      <CannotConnectWidget />
      <div className="position-absolute top-50 start-50 translate-middle">
        {statusComponent}
        <iframe src="https://giphy.com/embed/RgzryV9nRCMHPVVXPV" width="480" height="480" frameBorder="0" className="giphy-embed"></iframe>
        <div className="clickShield"></div>
      </div>
    </div>
  );
}

export default WaitingPage;
