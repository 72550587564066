class LocalStorageClient {
	constructor() {
	  this.playerName = localStorage.playerName;
	}

	getPlayerName() {
	  return this.playerName;
	}

	savePlayerName(playerName) {
	  localStorage.playerName = playerName;
	}
}

const client = new LocalStorageClient()
export default client
