import { MDBBtn } from 'mdb-react-ui-kit';

import './banner.css';

function Banner() {
  let letters = 'WORDLEBATTLE'.split('').map(l => {
    return <div className="banner-letter p-3 m-2">{l}</div>;
  })
  return (
    <div className="Banner">
      <div className="d-flex flex-row mb-3">
        {letters}
      </div>
    </div>
  );
}

export default Banner;
