
let verbs = [
  'Arguing',
  'Booping',
  'Crying',
  'Dancing',
  'Egging',
  'Flying',
  'Growing',
  'Hacking',
  'Igniting',
  'Jousting',
  'Kiting',
  'Laughing',
  'Mourning',
  'Nomming',
  'Opining',
  'Pointing',
  'Questing',
  'Running',
  'Sleeping',
  'Talking',
  'Umming',
  'Vexing',
  'Walking',
  'Yodeling'
];

let adjectives = [
  'Ambivalent',
  'Belevolent',
  'Careless',
  'Daring',
  'Exellent',
  'Fabulous',
  'Great',
  'Helpful',
  'Impish',
  'Joyous',
  'Kindly',
  'Luxurious',
  'Muscular',
  'Novice',
  'Opulent',
  'Pointy',
  'Queenly',
  'Rusty',
  'Sturdy',
  'Truthful',
  'Undying',
  'Violent',
  'Wishywashy',
  'Yucky'
];

let nouns = [
  'Apple',
  'Ant',
  'Banana',
  'Boar',
  'Cabbage',
  'Cat',
  'Dumpling',
  'Dog',
  'Eggplant',
  'Egret',
  'Falafal',
  'Fish',
  'Gatorade',
  'Goat',
  'Happymeal',
  'Horse',
  'Icecream',
  'Iguana',
  'Jawbreaker',
  'Jackal',
  'Kombucha',
  'Kangaroo',
  'Lemon',
  'Lemur',
  'Mango',
  'Mink',
  'Nigiri',
  'Newt',
  'Omelette',
  'Oriole',
  'Peach',
  'Penguin',
  'Quiche',
  'Quail',
  'Rice',
  'Raccoon',
  'Sandwich',
  'Salamander',
  'Turnip',
  'Turkey',
  'Uni',
  'Uni',
  'Vindaloo',
  'Vulture',
  'Watermelon',
  'Walrus',
  'Yam',
  'Yak'
];

let generateName = () => {
  return verbs[Math.floor(Math.random()*verbs.length)] + adjectives[Math.floor(Math.random()*adjectives.length)] + nouns[Math.floor(Math.random()*nouns.length)];
}
export default generateName;