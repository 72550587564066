import {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { MDBBtn,
           MDBModal,
           MDBModalDialog,
           MDBModalContent,
           MDBModalHeader,
           MDBModalTitle,
           MDBModalBody,
           MDBModalFooter, } from 'mdb-react-ui-kit';

import gameClient from '../../clients/GameClient.js';

function CannotConnectWidget() {
  let [showModal, setShowModal] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    gameClient.connectedChanged = (isConnected) => {
      setShowModal(!isConnected);
    }
  }, [])

  return (
    <MDBModal tabIndex='-1' show={showModal}>
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle>Unable To Connect to Server</MDBModalTitle>
          </MDBModalHeader>
          <MDBModalBody>
            <p>
              There is some problem connecting to the server.
            </p>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn color='info' onClick={() => {
              navigate('/');
              setShowModal(false);
            }}>
              Back to Main Page
            </MDBBtn>
          </MDBModalFooter>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}

export default CannotConnectWidget;
